<template>
  <div>
    <b-button
      :id="`no-of-passenger${idNoOfPassenger}`"
      class="rounded cursor-pointer mb-0 border-0 w-100"
      :class="{'px-1': !showTextPassenger}"
      variant="flat-dark"
      @click="onTogglePopover(true)"
    >
      <span class="text-body float-left d-flex-center">
        <img
          src="@icons/users.svg"
          class="mr-25"
        >
        <span v-if="showTextPassenger">
          {{
            `${adt} ${$t('flight.Adult')}, ${chd} ${$t('flight.Child')}, ${inf} ${$t('flight.Infant')}`
          }}
        </span>
      </span>
    </b-button>

    <b-popover
      :id="`popover1${idNoOfPassenger}`"
      ref="refPopover1"
      :target="`no-of-passenger${idNoOfPassenger}`"
      placement="bottom"
      triggers="focus hover"
      boundary="window"
      :show.sync="showPopover"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center">
          {{ $t('flight.numPassenger') }}
          <span
            class="p-25 cursor-pointer"
            @click="onTogglePopover"
          >
            <feather-icon
              icon="XIcon"
              size="20"
            />
          </span>
        </div>
      </template>

      <b-container>
        <b-row class="mb-50">
          <b-col cols="6">
            <strong>{{ $t('flight.Adult') }}</strong>
            <div class="text-muted text-nowrap">
              ({{ $t('flight.adultRange') }})
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-flex justify-content-between align-items-center">
              <b-button
                class="btn-icon"
                variant="gradient-primary"
                :disabled="adt === 1"
                @click="passengersHandle('adult', 'decrease')"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
              <span class="px-1">
                {{ adt }}
              </span>
              <b-button
                class="btn-icon"
                variant="gradient-primary"
                @click="passengersHandle('adult', 'increase')"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-50">
          <b-col cols="6">
            <strong>{{ $t('flight.Child') }}</strong>
            <div class="text-muted text-nowrap">
              ({{ $t('flight.childRange') }})
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-flex justify-content-between align-items-center">
              <b-button
                variant="gradient-primary"
                class="btn-icon"
                :disabled="chd === 0"
                @click="passengersHandle('child', 'decrease')"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
              <span class="px-1">
                {{ chd }}
              </span>
              <b-button
                variant="gradient-primary"
                class="btn-icon"
                @click="passengersHandle('child', 'increase')"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-50">
          <b-col cols="6">
            <strong>{{ $t('flight.Infant') }}</strong>
            <div class="text-muted text-nowrap">
              ({{ $t('flight.infantRange') }})
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-flex justify-content-between align-items-center">
              <b-button
                variant="gradient-primary"
                class="btn-icon"
                :disabled="inf === 0"
                @click="passengersHandle('infant', 'decrease')"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
              <span class="px-1">
                {{ inf }}
              </span>
              <b-button
                class="btn-icon"
                variant="gradient-primary"
                @click="passengersHandle('infant', 'increase')"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-popover>
  </div>
</template>

<script>
import {
  BContainer,
  BPopover,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ref, toRefs, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BPopover,
    BButton,
    BRow,
    BCol,
  },
  props: {
    adult: {
      type: Number,
      default: 1,
    },
    child: {
      type: Number,
      default: 0,
    },
    infant: {
      type: Number,
      default: 0,
    },
    isSearchClassBooking: {
      type: Boolean,
      default: false,
    },
    idNoOfPassenger: {
      type: String,
      default: '',
    },
    showTextPassenger: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const toast = useToast()

    const limitPaxBooking = computed(() => store.getters['userStore/getLimitPaxBooking'])
    const {
      adult: adt, child: chd, infant: inf,
    } = toRefs(props)

    function emitProp(type, amount) {
      emit(`update:${type}`, amount)
    }

    function passengersHandle(type, value) {
      let amount
      const totalPassenger = adt.value + chd.value
      switch (type) {
        case 'adult': amount = adt.value; break
        case 'child': amount = chd.value; break
        case 'infant': amount = inf.value; break
        default: amount = 0
      }

      if ((type === 'adult' || type === 'child') && value === 'increase') {
        if (totalPassenger < limitPaxBooking.value) {
          emitProp(type, amount + 1)
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('flight.passengerMore', { limitPax: limitPaxBooking.value }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
      if (type === 'infant' && value === 'increase') {
        const amount = inf.value
        if (inf.value < adt.value) {
          emitProp(type, amount + 1)
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Số em bé không được nhiều hơn số lượng hành khách người lớn.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
      if (value === 'decrease' && amount >= 1) {
        emit(`update:${type}`, amount - 1)
        if (adt.value === 0) {
          emit('update:adult', 1)
        }
        if (type !== 'child' && inf.value >= adt.value) {
          emit('update:infant', amount - 1)
        }
      }
    }

    // ANCHOR handle popover
    const showPopover = ref(false)
    const onTogglePopover = (status = null) => {
      if (status === true || status === false) {
        showPopover.value = status
        return
      }
      showPopover.value = !showPopover.value
    }

    return {
      // data
      adt,
      chd,
      inf,

      // methods
      passengersHandle,

      // popover
      showPopover,
      onTogglePopover,
      limitPaxBooking,
    }
  },
}
</script>

<style lang="scss" scoped>
#popover {
  max-width: none;
}
</style>
