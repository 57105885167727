var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    staticClass: "rounded cursor-pointer mb-0 border-0 w-100",
    class: {
      'px-1': !_vm.showTextPassenger
    },
    attrs: {
      "id": "no-of-passenger".concat(_vm.idNoOfPassenger),
      "variant": "flat-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.onTogglePopover(true);
      }
    }
  }, [_c('span', {
    staticClass: "text-body float-left d-flex-center"
  }, [_c('img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/users.svg")
    }
  }), _vm.showTextPassenger ? _c('span', [_vm._v(" " + _vm._s("".concat(_vm.adt, " ").concat(_vm.$t('flight.Adult'), ", ").concat(_vm.chd, " ").concat(_vm.$t('flight.Child'), ", ").concat(_vm.inf, " ").concat(_vm.$t('flight.Infant'))) + " ")]) : _vm._e()])]), _c('b-popover', {
    ref: "refPopover1",
    attrs: {
      "id": "popover1".concat(_vm.idNoOfPassenger),
      "target": "no-of-passenger".concat(_vm.idNoOfPassenger),
      "placement": "bottom",
      "triggers": "focus hover",
      "boundary": "window",
      "show": _vm.showPopover
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showPopover = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.numPassenger')) + " "), _c('span', {
          staticClass: "p-25 cursor-pointer",
          on: {
            "click": _vm.onTogglePopover
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon",
            "size": "20"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-container', [_c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('flight.Adult')))]), _c('div', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(" (" + _vm._s(_vm.$t('flight.adultRange')) + ") ")])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "gradient-primary",
      "disabled": _vm.adt === 1
    },
    on: {
      "click": function click($event) {
        return _vm.passengersHandle('adult', 'decrease');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MinusIcon"
    }
  })], 1), _c('span', {
    staticClass: "px-1"
  }, [_vm._v(" " + _vm._s(_vm.adt) + " ")]), _c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.passengersHandle('adult', 'increase');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('flight.Child')))]), _c('div', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(" (" + _vm._s(_vm.$t('flight.childRange')) + ") ")])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "gradient-primary",
      "disabled": _vm.chd === 0
    },
    on: {
      "click": function click($event) {
        return _vm.passengersHandle('child', 'decrease');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MinusIcon"
    }
  })], 1), _c('span', {
    staticClass: "px-1"
  }, [_vm._v(" " + _vm._s(_vm.chd) + " ")]), _c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.passengersHandle('child', 'increase');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('flight.Infant')))]), _c('div', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(" (" + _vm._s(_vm.$t('flight.infantRange')) + ") ")])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "gradient-primary",
      "disabled": _vm.inf === 0
    },
    on: {
      "click": function click($event) {
        return _vm.passengersHandle('infant', 'decrease');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MinusIcon"
    }
  })], 1), _c('span', {
    staticClass: "px-1"
  }, [_vm._v(" " + _vm._s(_vm.inf) + " ")]), _c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.passengersHandle('infant', 'increase');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }