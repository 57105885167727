var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-register-member-ship-card",
            "title": _vm.$t('flight.modalMemberShipCard'),
            "title-class": "font-weight-bolder text-airline font-medium-3",
            "centered": "",
            "size": "lg",
            "no-close-on-backdrop": ""
          },
          on: {
            "show": _vm.handleOpenModal
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var close = _ref2.close;
              return [_c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(186, 191, 199, 0.15)',
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "center rounded-pill width-100",
                attrs: {
                  "size": "md",
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return close();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient border-0",
                attrs: {
                  "disabled": invalid || pristine,
                  "pill": ""
                },
                on: {
                  "click": _vm.handleCreateMembershipCard
                }
              }, [_c('span', {
                staticClass: "align-middle mb-25"
              }, [_vm._v(_vm._s(_vm.$t('reservation.confirm')))])])];
            }
          }], null, true)
        }, [_c('b-form', [_c('b-card', {
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "body-class": "pb-50"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" " + _vm._s(_vm.$t('flight.passengerInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-row', {
          staticClass: "mt-50"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('flight.Honorific'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "honorific"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('flight.Honorific')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                staticClass: "select-size-md",
                attrs: {
                  "id": "honorific",
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": _vm.titleOpts,
                  "label": "text",
                  "clearable": false,
                  "placeholder": "".concat(_vm.$t('flight.placeholderSelect')),
                  "reduce": function reduce(val) {
                    return val.value;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', {
                      staticClass: "font-small-3"
                    }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', {
                      staticClass: "font-small-3"
                    }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                  }
                }], null, true),
                model: {
                  value: _vm.membershipCardDataToAdd.title,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "title", $$v);
                  },
                  expression: "membershipCardDataToAdd.title"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "honorific",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger",
                  "title": validationContext.errors[0]
                }
              }, [_c('span', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Birthday",
            "rules": _vm.membershipCardDataToAdd.title ? _vm.getValidateBirthday(_vm.membershipCardDataToAdd.title) : _vm.getValidateBirthday('MR'),
            "vid": "dob"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-0",
                attrs: {
                  "id": "dob-target"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "font-small-4"
                    }, [_vm._v(" " + _vm._s(_vm.$t('flight.dob')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('flat-pickr', {
                staticClass: "d-none",
                model: {
                  value: _vm.membershipCardDataToAdd.birthDay,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "birthDay", $$v);
                  },
                  expression: "membershipCardDataToAdd.birthDay"
                }
              }), _c('div', {
                staticClass: "form-control p-0",
                class: validationContext.errors[0] ? 'is-invalid' : ''
              }, [_c('flat-pickr', {
                staticClass: "form-control px-50 py-0",
                style: {
                  height: '40px'
                },
                attrs: {
                  "id": "dob-",
                  "name": _vm.$t('flight.dob'),
                  "config": Object.assign({}, _vm.configFlatPickr, {
                    altInputClass: 'form-control input px-50 py-0 border-transparent'
                  }),
                  "placeholder": _vm.$t('flight.placeholderInput')
                },
                model: {
                  value: _vm.membershipCardDataToAdd.birthDay,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "birthDay", $$v);
                  },
                  expression: "membershipCardDataToAdd.birthDay"
                }
              })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "dob-target",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger",
                  "title": validationContext.errors[0]
                }
              }, [_c('span', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('flight.lastName'),
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "last-name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.lastName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "last-name",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.formatterInput,
                  "placeholder": _vm.$t('flight.placeholderInput')
                },
                model: {
                  value: _vm.membershipCardDataToAdd.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "lastName", $$v);
                  },
                  expression: "membershipCardDataToAdd.lastName"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "last-name",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger",
                  "title": validationContext.errors[0]
                }
              }, [_c('span', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('flight.firstName'),
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "first-name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.firstName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "first-name",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.formatterInput,
                  "placeholder": _vm.$t('flight.placeholderInput')
                },
                model: {
                  value: _vm.membershipCardDataToAdd.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "firstName", $$v);
                  },
                  expression: "membershipCardDataToAdd.firstName"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "first-name",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger",
                  "title": validationContext.errors[0]
                }
              }, [_c('span', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('flight.email'),
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "email"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.email')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "50",
                  "debounce": 300,
                  "formatter": _vm.removeAccentsFormatter,
                  "placeholder": _vm.$t('flight.placeholderInput')
                },
                model: {
                  value: _vm.membershipCardDataToAdd.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "email", $$v);
                  },
                  expression: "membershipCardDataToAdd.email"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "email",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger",
                  "title": validationContext.errors[0]
                }
              }, [_c('span', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('flight.phone'),
            "rules": "required|phoneNumber"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "phone"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.phone')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('div', {
                staticClass: "d-flex"
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "phone",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "debounce": 300,
                  "formatter": _vm.formatterInput,
                  "placeholder": _vm.$t('flight.placeholderInput')
                },
                model: {
                  value: _vm.membershipCardDataToAdd.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "phoneNumber", $$v);
                  },
                  expression: "membershipCardDataToAdd.phoneNumber"
                }
              })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "phone",
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger",
                  "title": validationContext.errors[0]
                }
              }, [_c('span', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('flight.nationality'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "nationality"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.nationality')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "nationality",
                  "options": _vm.nationalityOptions,
                  "clearable": false,
                  "label": "text",
                  "disabled": true,
                  "placeholder": "".concat(_vm.$t('flight.placeholderSelect'))
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(data.text) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(data.text) + " ")])];
                  }
                }], null, true),
                model: {
                  value: _vm.membershipCardDataToAdd.nationality,
                  callback: function callback($$v) {
                    _vm.$set(_vm.membershipCardDataToAdd, "nationality", $$v);
                  },
                  expression: "membershipCardDataToAdd.nationality"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1)], 1)], 1), !_vm.isResult ? _c('b-card', {
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "body-class": "pb-50"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" " + _vm._s(_vm.$t('flight.selectAirlineCreateMembershipCard')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('validation-provider', {
          attrs: {
            "tag": "div",
            "vid": "vidTicketType",
            "rules": "required",
            "name": _vm.$t('invoice.ticketType')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('div', {
                staticClass: "custom-label-checkbox mt-1"
              }, [_c('b-form-group', {
                staticClass: "mb-0",
                attrs: {
                  "label-class": "font-weight-bold font-medium-2",
                  "label": "".concat(_vm.$t('flight.labelSelectAirlines'), ":"),
                  "state": errors[0] ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('b-form-checkbox', {
                      staticClass: "custom-checkbox-label mb-1",
                      attrs: {
                        "aria-describedby": "flights",
                        "aria-controls": "flights"
                      },
                      on: {
                        "change": _vm.toggleAll
                      },
                      model: {
                        value: _vm.allSelected,
                        callback: function callback($$v) {
                          _vm.allSelected = $$v;
                        },
                        expression: "allSelected"
                      }
                    }, [_c('div', {
                      staticClass: "text-body-2"
                    }, [_vm._v(" " + _vm._s(_vm.allSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])])];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function fn(_ref4) {
                    var ariaDescribedby = _ref4.ariaDescribedby;
                    return [_c('b-form-checkbox-group', {
                      attrs: {
                        "aria-describedby": ariaDescribedby,
                        "stacked": "",
                        "name": "flights"
                      },
                      model: {
                        value: _vm.airlines,
                        callback: function callback($$v) {
                          _vm.airlines = $$v;
                        },
                        expression: "airlines"
                      }
                    }, _vm._l(_vm.airlineOptions, function (data, index) {
                      return _c('div', {
                        key: index,
                        staticClass: "d-flex"
                      }, [_c('b-form-checkbox', {
                        staticClass: "custom-checkbox-label mb-50",
                        attrs: {
                          "value": data
                        }
                      }, [_c('span', {
                        staticClass: "text-airline text-nowrap mb-50",
                        class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
                      }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " (" + _vm._s(data.code === 'VN' ? "".concat(_vm.$t('reservation.BSV')) : "".concat(_vm.$t('reservation.BBClub'))) + ") ")])])], 1);
                    }), 0)];
                  }
                }], null, true)
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _c('b-card', {
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "body-class": "pb-50"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" " + _vm._s(_vm.$t('flight.creatingMembershipCard')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, _vm._l(_vm.airlines, function (data, index) {
          var _vm$resultData$find, _vm$resultData$find2;
          return _c('div', {
            key: index,
            staticClass: "d-flex-between"
          }, [_c('div', {
            staticClass: "text-airline text-nowrap my-50",
            class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
          }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " (" + _vm._s(data.code === 'VN' ? "".concat(_vm.$t('reservation.BSV')) : "".concat(_vm.$t('reservation.BBClub'))) + ") ")]), (_vm$resultData$find = _vm.resultData.find(function (resItem) {
            return resItem.airline.code === data.code;
          })) !== null && _vm$resultData$find !== void 0 && _vm$resultData$find.cardNumber ? _c('div', {
            staticClass: "d-flex-center gap-2"
          }, [_vm._v(" Số thẻ: "), _c('span', {
            staticClass: "text-info font-weight-bolder ml-25"
          }, [_vm._v(" " + _vm._s(_vm.resultData.find(function (resItem) {
            return resItem.airline.code === data.code;
          }).cardNumber) + " ")]), _vm.airlineLists.includes(data.code) ? _c('BButton', {
            staticClass: "ml-25 p-25 rounded-pill",
            attrs: {
              "variant": "outline-info"
            },
            on: {
              "click": _vm.applyMembershipCard
            }
          }, [_vm._v(" Áp dụng ")]) : _vm._e()], 1) : (_vm$resultData$find2 = _vm.resultData.find(function (resItem) {
            return resItem.airline.code === data.code;
          })) !== null && _vm$resultData$find2 !== void 0 && _vm$resultData$find2.msgError ? _c('div', {
            staticClass: "text-danger"
          }, [_vm._v(" Lỗi: " + _vm._s(_vm.resultData.find(function (resItem) {
            return resItem.airline.code === data.code;
          }).msgError) + " ")]) : _c('div', [_c('b-spinner', {
            attrs: {
              "variant": "primary",
              "small": "",
              "tag": "span"
            }
          })], 1)]);
        }), 0)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }