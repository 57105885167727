<template>
  <BModal
    :id="`modal-result-recalculate-price-class-booking-${modalId}`"
    title="Tính giá"
    title-class="text-airline font-medium-4 font-weight-bolder"
    header-class="px-1 py-50"
    body-class="px-50"
    centered
    no-close-on-backdrop
    @show="handleShowModal"
  >
    <template #modal-footer="{ close }">
      <BButton
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill px-2 mr-1"
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </BButton>

      <BButton
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient px-2 px-md-3"
        pill
        :disabled="isEmpty(arrIndexTripSelectSort)"
        @click="submitRecalculatePrice"
      >
        <span class="align-middle"> {{ $t('reservation.recalculatePrice.title') }} </span>
      </BButton>
    </template>

    <BCard
      v-if="!['VN1A'].includes(airlineSearch)"
      body-class="py-1 px-50 px-md-1"
    >
      <div
        v-if="['1G'].includes(airlineSearch)"
        class="fw-700 font-medium-2"
      >
        {{ $t('flight.combinatePrice') }}
      </div>

      <BFormRadioGroup
        id="calc-mode"
        v-model="calcMode"
        :options="getCalcModeOption"
        stacked
      />
    </BCard>

    <BAlert
      show
      variant="warning"
      class="mb-75 py-50 px-1 text-center"
    >
      <span class="text-airline font-italic">
        {{ $t("flight['For Round-trip flights, please select Calculate combined price to get the exact price']") }}
      </span>
    </BAlert>

    <BCard body-class="py-1 px-50 px-md-1">
      <div class="fw-700 font-medium-2">
        {{ ['VN1A'].includes(airlineSearch) ? 'Hành trình' : $t('reservation.specialServices.itineraries.title') }}
      </div>
      <div v-if="dataTripsToCalcPrice">
        <BFormCheckboxGroup
          id="checkbox-group-trip-calc"
          v-model="arrIndexTripSelect"
          stacked
        >
          <div
            v-for="(trip, indexTrip) in dataTripsToCalcPrice"
            :key="indexTrip"
            class="d-flex-column my-75"
          >
            <BFormCheckbox
              :value="indexTrip"
              :disabled="['VN1A'].includes(airlineSearch)"
            >
              <div
                v-for="(segment, indexSegment) in trip"
                :key="indexSegment"
                class=""
              >
                <span class="text-dark fw-700 pl-md-25">
                  {{ getSortTripBySegment(segment, true) }}
                </span>
              </div>
            </BFormCheckbox>
          </div>
        </BFormCheckboxGroup>

        <!-- <div
          v-for="(trip, tripIndex) of dataTripsToCalcPrice"
          :key="tripIndex"
        >
          <BCard
            class="border mb-50"
            body-class="py-75 px-50"
          >
            <div>
              <feather-icon
                v-if="true"
                icon="CheckCircleIcon"
                :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
                class="p-25 text-info"
                @click="handleSelectTrip(trip, tripIndex)"
              />
              <feather-icon
                v-else
                icon="CircleIcon"
                :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
                class="p-25 text-secondary"
                @click="handleSelectTrip()"
              />
            </div>

            <BRow
              v-for="(segment, segmentIndex) in trip"
              :key="segmentIndex"
              no-gutters
              :class="`border-danger ${segmentIndex > 0 ? 'mt-1' : ''}`"
            >
              <BCol
                cols="12"
                class="text-dark font-weight-bold"
              >
                <p
                  class="font-weight-bolder d-flex-center flex-wrap mb-0"
                  :class="isMobileView ? 'font-small-4' : 'font-medium-2'"
                >
                  {{ getSortTripBySegment(segment, true) }}
                </p>
              </BCol>
            </BRow>
          </BCard>
        </div> -->
      </div>
    </BCard>

    <BCard
      v-if="!isEmpty(dataAddMoreFlight)"
      body-class="py-1 px-50 px-md-1"
    >
      <div class="fw-700 font-medium-2">
        <!-- {{ $t('reservation.specialServices.itineraries.title') }} -->
        Hành trình thêm
      </div>

      <BFormCheckboxGroup
        id="checkbox-group-trip-calc"
        v-model="arrIndexAddMoreTripSelect"
        stacked
      >
        <div
          v-for="(trip, indexTrip) in dataAddMoreFlight"
          :key="trip.tripUuid"
          class="d-flex-column my-75"
        >
          <BFormCheckbox
            :value="indexTrip"
            disabled
          >
            <div
              v-for="(segment, indexSegment) in trip.segments"
              :key="indexSegment"
            >
              <span class="text-dark fw-700 pl-md-25">
                {{ getSortTripBySegment(segment, true) }}
              </span>
            </div>
          </BFormCheckbox>
        </div>
      </BFormCheckboxGroup>
    </BCard>

    <!-- ANCHOR - Passenger -->
    <BCard body-class="py-1 px-50 px-md-1">
      <div class="fw-700 font-medium-1 mb-25">
        {{ $t('flight.numPassenger') }}
      </div>

      <BRow class="justify-content-start">
        <BCol
          v-for="(pax, indexPax) of countPaxs"
          :key="indexPax"
          cols="auto"
        >
          <span
            v-if="pax.count > 0"
            :class="indexPax !== countPaxs.length - 1 ? 'mr-1' : ''"
          >
            <span class="font-small-4">{{ pax.title }}: </span>
            <span class="text-danger fw-700 font-medium-3">{{ pax.count }}</span>
          </span>
        </BCol>
      </BRow>
    </BCard>

    <!-- ANCHOR - BargainFinder -->
    <b-card
      v-if="['1G', 'VN1A'].includes(airlineSearch)"
      body-class="py-75 py-lg-1"
    >
      <div class="margin-checkbox-label d-flex-center justify-content-around gap-2 text-center">
        <b-form-checkbox
          v-model="isBargainFinder"
          name="retain-checkbox"
          class="custom-control-success"
        >
          <span
            class="font-medium-1 font-weight-bolder"
            :class="isBargainFinder ? 'text-success' : 'text-dark'"
          >
            {{ $t('reservation.bargainFinder') }}
          </span>
        </b-form-checkbox>
      </div>

      <b-alert
        v-if="['VN1A'].includes(airlineSearch) && isBargainFinder"
        variant="warning"
        show
        class="text-center px-50 py-50 my-50 fw-700 font-medium-1"
      >
        <feather-icon
          icon="InfoIcon"
          size="21"
          class="text-warning mr-25"
          style="margin-bottom: 3px"
        />
        Sau khi tính giá rẻ nhất thành công sẽ tự động đổi hạng vé đã chọn.
      </b-alert>
    </b-card>
  </BModal>
</template>

<script>
import {
  BModal,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BButton,
  BCard,
  BCol,
  BRow,
  BAlert,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  watch,
} from '@vue/composition-api'

import { convertISODateTime, convertShortTrip } from '@/@core/utils/filter'

import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BButton,
    BCard,
    BCol,
    BRow,
    BAlert,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      status,
      airlineSearch,
      selectedTrips,
      storeSearchFlight,
      dataAddMoreFlight,
      isEveryDomesticFlights,
      isEmpty,
      countPaxs,
      delay,
      toastSuccess,
      toastWarning,
      recalculatePrice,
      getSortTripBySegment,
    } = useClassBookingHandle()

    const isBargainFinder = ref(false)
    const loading = ref(false)
    const dataPriceCalculated = ref(null)
    const calcMode = ref('SINGLE')

    const arrIndexTripSelect = ref([])
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const arrIndexTripSelectSort = computed(() => !isEmpty(arrIndexTripSelect.value) ? arrIndexTripSelect.value.sort() : arrIndexTripSelect.value)

    const arrIndexAddMoreTripSelect = ref([])
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const arrIndexAddMoreTripSelectSort = computed(() => !isEmpty(arrIndexAddMoreTripSelect.value) ? arrIndexAddMoreTripSelect.value.sort() : arrIndexAddMoreTripSelect.value)

    watch(() => isEveryDomesticFlights.value, val => {
      if (val === false) {
        isBargainFinder.value = true
      }
    }, { deep: true })

    const dataTripsToCalcPrice = ref(null)

    // const countPaxs = computed(() => {
    //   const { adult = 0, child = 0, infant = 0 } = storeSearchFlight.value || {}
    //   const paxs = [
    //     { title: 'Người lớn', code: 'ADULT', count: adult },
    //     { title: 'Trẻ em', code: 'CHILD', count: child },
    //     { title: 'Em bé', code: 'INFANT', count: infant },
    //   ]
    //   return paxs.filter(pax => pax.count > 0)
    // })

    function handleShowModal() {
      if (isEveryDomesticFlights.value !== false) {
        isBargainFinder.value = false
      }

      dataTripsToCalcPrice.value = selectedTrips.value.map((trip, indexTrip) => trip.map((segment, indexSegment) => ({
        ...segment,
        status: status.value[indexTrip][indexSegment] || status.value[indexTrip],
      })))

      arrIndexTripSelect.value = dataTripsToCalcPrice.value?.map((i, index) => index)
      if (!isEmpty(dataAddMoreFlight.value)) {
        arrIndexAddMoreTripSelect.value = dataAddMoreFlight.value?.map((i, index) => index)
      }

      // calcMode.value = 'SINGLE'
      if (['VN1A'].includes(airlineSearch.value)) {
        const allTrip = [...dataTripsToCalcPrice.value, ...arrIndexAddMoreTripSelect.value]
        if (allTrip.length > 1) {
          calcMode.value = 'COMBINE'
        }
      } else {
        calcMode.value = 'SINGLE'
      }
    }

    const CALC_MODE_OPTIONS = [
      // { html: '<b class="font-medium-1">Tính giá lẻ từng hành trình</b>', value: 'SINGLE' },
      { html: '<b class="font-medium-1">Tính giá kết hợp</b>', value: 'COMBINE' },
    ]

    const getCalcModeOption = computed(() => ['1G'].includes(airlineSearch.value)
      ? [...CALC_MODE_OPTIONS, { html: '<b class="font-medium-1">Tính giá lẻ từng hành trình</b>', value: 'SINGLE' }]
      : CALC_MODE_OPTIONS)

    async function submitRecalculatePrice() {
      if (['COMBINE'].includes(calcMode.value) && !['VN1A'].includes(airlineSearch.value) && (arrIndexTripSelect.value.length < 2)) {
        toastWarning({
          title: 'To calculate the combined price, you need to choose 2 or more itineraries',
        })
        return
      }

      const selectedTrips = [
        ...dataTripsToCalcPrice.value.filter((i, index) => arrIndexTripSelect.value.includes(index)),
        ...dataAddMoreFlight.value.filter((i, index) => arrIndexAddMoreTripSelectSort.value.includes(index)).map(t => t.segments),
      ]

      if (['VN1A'].includes(airlineSearch.value)) {
        const totalLengthPaxSeats = countPaxs.value.reduce((total, item) => total + (['ADULT', 'CHILD'].includes(item.code) ? item.count : 0), 0)
        const isInValidSeats = selectedTrips.some(trip => trip.some(seg => !Number.isNaN(Number(seg.bookingClass.availability)) && Number(seg.bookingClass.availability) < totalLengthPaxSeats))

        if (isInValidSeats) {
          toastWarning({
            title: 'Có hành trình không đủ số ghế cho tất cả hành khách, vui lòng kiểm tra và chọn lại !',
          })
          return
        }
      }

      const payload = {
        passengerTypeRequests: countPaxs.value.map(item => ({ code: item.code, quantity: item.count })),
        selectedTrips,
        bargainFinder: isBargainFinder.value,
        calcMode: calcMode.value,
      }

      try {
        this.$bvModal.show('modal-api-loading')
        const isAllSuccess = await recalculatePrice(payload)

        if (isAllSuccess) {
          toastSuccess({
            title: 'messagesList.success',
          })

          if (['result'].includes(props.modalId)) {
            await delay(200)
            emit('scrollToCardShowPrice')
          }
        }
      } catch (error) {
        console.error({ error })
      } finally {
        this.$bvModal.hide('modal-api-loading')
        this.$bvModal.hide(`modal-result-recalculate-price-class-booking-${props.modalId}`)
      }
    }

    return {
      loading,
      dataPriceCalculated,
      calcMode,
      arrIndexTripSelect,
      arrIndexTripSelectSort,
      arrIndexAddMoreTripSelect,
      arrIndexAddMoreTripSelectSort,
      getCalcModeOption,
      CALC_MODE_OPTIONS,
      isBargainFinder,
      isEmpty,
      dataTripsToCalcPrice,
      submitRecalculatePrice,
      convertISODateTime,
      handleShowModal,
      convertShortTrip,
      countPaxs,
      getSortTripBySegment,
      airlineSearch,
      dataAddMoreFlight,
    }
  },
}
</script>

<style lang="scss" scoped>
.margin-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}

#calc-mode::v-deep {
  & > * {
    margin: 8px 0 !important;
  }
  .custom-control-label {
    margin-top: -1px !important;
  }
}
</style>
