<template>
  <div id="dropdown-type">
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="typeTemp"
        :aria-describedby="ariaDescribedby"
        @change="$emit('update:type', typeTemp)"
      >
        <b-form-radio
          value="OW"
          plain
          class="custom-control-warning"
        >
          <div class="text-dark font-weight-bolder">
            {{ $t('flight.OW') }}
          </div>
        </b-form-radio>
        <b-form-radio
          plain
          value="RT"
          class="custom-control-warning"
        >
          <div class="text-dark font-weight-bolder">
            {{ $t('flight.RT') }}
          </div>
        </b-form-radio>
        <b-form-radio
          plain
          value="MC"
          class="custom-control-warning"
        >
          <div class="text-dark font-weight-bolder">
            {{ $t('flight.MC') }}
          </div>
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>
<script>
import { BFormGroup, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BFormGroup, BFormRadioGroup, BFormRadio,
  },
  props: {
    type: {
      type: String,
      default: 'OW',
    },
  },
  setup(props) {
    const typeTemp = ref(props.type)
    watch(() => props.type, val => {
      if (val) {
        typeTemp.value = val
      }
    }, { immediate: true })
    return {
      typeTemp,
    }
  },
}
</script>

<style lang="scss" scoped>
#dropdown-type ::v-deep {
  label.custom-control-label {
    margin-top: 0px;
  }
}
</style>
