<template>
  <div
    id="search-source-select"
    class="d-flex-center"
  >
    <img
      src="@icons/plane-ticket.svg"
      class="mr-25"
    >
    <v-select
      :value="valueProp"
      :class="`w-100 ${isSearchClassBooking ? '' : 'remove_action' }`"
      :multiple="!isSearchClassBooking"
      :disabled="isSearchMonthlyCheapestFare"
      :reduce="val => val.toUpperCase()"
      :clearable="false"
      :options="sourceOptions"
      label="title"
      append-to-body
      :calculate-position="withPopper"
      :placeholder="$t('flight.airlineSearchPlaceholder')"
      @option:selected="handleUpdate"
      @option:deselecting="handleDeselectingAirline"
      @open="handleOpenSelect"
    >
      <template #selected-option-container="{ option, deselect }">
        <!-- :style="`${ isCarrierSearch && opt === '1G' ? 'background-color: #EFEFEF !important' : ''}`" -->
        <div
          v-for="opt in option"
          :key="opt"
          class="vs__selected"
        >
          {{ $te(`flight.sourceName.${opt.toUpperCase()}`)
            ? $t(`flight.sourceName.${opt.toUpperCase()}`)
            : opt.toUpperCase() }}

          <!-- v-if="!(isCarrierSearch && opt === '1G')" -->
          <!-- :disabled="isCarrierSearch && opt === '1G'" -->
          <!-- v-if="!(isCarrierSearch)" -->
          <button
            type="button"
            class="vs__deselect"
            @click.stop="deselect(opt)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14px"
              height="14px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            ><line
              x1="18"
              y1="6"
              x2="6"
              y2="18"
            /><line
              x1="6"
              y1="6"
              x2="18"
              y2="18"
            /></svg>
          </button>
        </div>
      </template>

      <template #option="data">
        <div class="d-flex-center gap-1">
          <div
            style="width: 50px"
            class="d-flex-center"
          >
            <IAmLogoAirline
              :airline="data.title.toUpperCase() "
              :height="30"
            />
          </div>
          <span class="flex-1">
            {{ $te(`flight.airlines.${data.title.toUpperCase()}`)
              ? $t(`flight.airlines.${data.title.toUpperCase()}`)
              : '' }}
            ({{ $te(`flight.sourceName.${data.title.toUpperCase()}`)
              ? $t(`flight.sourceName.${data.title.toUpperCase()}`)
              : data.title.toUpperCase() }})
          </span>
        </div>
      </template>
      <template #no-options>
        {{ $t('loading') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { computed, ref, toRefs } from '@vue/composition-api'
import { createPopper } from '@popperjs/core'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

export default {
  components: {
    vSelect,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    airlines: {
      type: Array,
      default: () => [],
    },
    isSearchClassBooking: {
      type: Boolean,
      required: true,
    },
    isSearchMonthlyCheapestFare: {
      type: Boolean,
      required: true,
    },
    permittedCarriers: {
      type: [Array],
      default: () => [],
    },
  },
  methods: {
    withPopper(dropdownList, component) {
      const dropdownWidth = this.isMobileView ? '380px' : '400px'
      dropdownList.style.width = dropdownWidth
      dropdownList.style.maxHeight = '300px'
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const isProduction = computed(() => process.env.VUE_APP_ENV === 'production')
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const sourceOptionsSearchClassBooking = computed(() => isProduction.value && isRoleF3.value ? ['VN1A', 'VU'] : ['VN1A', 'VU', '1G'])

    const sourceOptionsOnly1G = ref(['1G', 'NDC', 'GDS', '1A'])
    const valueProp = toRefs(props).airlines
    const isSearchClassBooking = toRefs(props).isSearchClassBooking
    const distributorOptions = computed(() => {
      const data = store.getters['app-flight-v2/getDistributorOptions']
      return data?.filter(i => isProduction.value ? (i !== 'VN1A_MT') : true) || []
    })
    const isCarrierSearch = computed(() => props.permittedCarriers?.length > 0)
    const sourceOptions = computed(() => {
      if (isCarrierSearch.value && !isSearchClassBooking.value) return sourceOptionsOnly1G.value
      return isSearchClassBooking.value ? sourceOptionsSearchClassBooking.value : distributorOptions.value
    })

    function handleOpenSelect() {
      if (isEmpty(sourceOptions.value)) {
        store.dispatch('app-flight-v2/setDistributorOptions')
      }
    }

    const handleUpdate = val => {
      if (isSearchClassBooking.value) {
        emit('update:airlines', [val])
      } else {
        emit('update:airlines', val)
      }
    }

    function handleDeselectingAirline(val) {
      const array = valueProp.value.filter(item => item !== val)
      handleUpdate(array)
    }

    return {
      valueProp,
      sourceOptions,
      sourceOptionsSearchClassBooking,
      handleDeselectingAirline,
      handleUpdate,
      handleOpenSelect,
      isCarrierSearch,
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';

::v-deep .vs__search {
  height: 50px;
}

#search-source-select ::v-deep {

  .vs__search {
    height: auto;
  }

  .vs__deselect {
    border-radius: 4px;
    // background-color: rgba(234, 84, 85, 0.9);
    color: #EF5350;
  }

  .vs__selected {
    // background-color: rgb(230,244,252);
    background-color: transparent;
    color: $dark;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 4px 8px;
    flex-grow: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem;
  }

  .vs__selected-options {
    display: flex;
    // flex-wrap: nowrap;
  }

  .vs__dropdown-toggle {
    border: none;
  }

  // .vs__dropdown-option.vs__dropdown-option--highlight {
  //   background-color: rgb(230,244,252);
  //   color: rgb(57, 166, 224) !important;
  // }
  // .vs__dropdown-option.vs__dropdown-option--selected::hover {
  //   color:white !important;
  // }

  // .vs__dropdown-menu{
  // display: none;
  // }
}

#search-source-select .remove_action ::v-deep{
  .vs__actions {
    display: none;
  }
}
</style>
