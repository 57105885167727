var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "dropdown-type"
    }
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          attrs: {
            "aria-describedby": ariaDescribedby
          },
          on: {
            "change": function change($event) {
              return _vm.$emit('update:type', _vm.typeTemp);
            }
          },
          model: {
            value: _vm.typeTemp,
            callback: function callback($$v) {
              _vm.typeTemp = $$v;
            },
            expression: "typeTemp"
          }
        }, [_c('b-form-radio', {
          staticClass: "custom-control-warning",
          attrs: {
            "value": "OW",
            "plain": ""
          }
        }, [_c('div', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.OW')) + " ")])]), _c('b-form-radio', {
          staticClass: "custom-control-warning",
          attrs: {
            "plain": "",
            "value": "RT"
          }
        }, [_c('div', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.RT')) + " ")])]), _c('b-form-radio', {
          staticClass: "custom-control-warning",
          attrs: {
            "plain": "",
            "value": "MC"
          }
        }, [_c('div', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.MC')) + " ")])])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }