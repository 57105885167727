var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center",
    attrs: {
      "id": "search-source-select"
    }
  }, [_c('img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/plane-ticket.svg")
    }
  }), _c('v-select', {
    class: "w-100 ".concat(_vm.isSearchClassBooking ? '' : 'remove_action'),
    attrs: {
      "value": _vm.valueProp,
      "multiple": !_vm.isSearchClassBooking,
      "disabled": _vm.isSearchMonthlyCheapestFare,
      "reduce": function reduce(val) {
        return val.toUpperCase();
      },
      "clearable": false,
      "options": _vm.sourceOptions,
      "label": "title",
      "append-to-body": "",
      "calculate-position": _vm.withPopper,
      "placeholder": _vm.$t('flight.airlineSearchPlaceholder')
    },
    on: {
      "option:selected": _vm.handleUpdate,
      "option:deselecting": _vm.handleDeselectingAirline,
      "open": _vm.handleOpenSelect
    },
    scopedSlots: _vm._u([{
      key: "selected-option-container",
      fn: function fn(_ref) {
        var option = _ref.option,
          deselect = _ref.deselect;
        return _vm._l(option, function (opt) {
          return _c('div', {
            key: opt,
            staticClass: "vs__selected"
          }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(opt.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(opt.toUpperCase())) : opt.toUpperCase()) + " "), _c('button', {
            staticClass: "vs__deselect",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return deselect(opt);
              }
            }
          }, [_c('svg', {
            staticClass: "feather feather-x",
            attrs: {
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14px",
              "height": "14px",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }
          }, [_c('line', {
            attrs: {
              "x1": "18",
              "y1": "6",
              "x2": "6",
              "y2": "18"
            }
          }), _c('line', {
            attrs: {
              "x1": "6",
              "y1": "6",
              "x2": "18",
              "y2": "18"
            }
          })])])]);
        });
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('div', {
          staticClass: "d-flex-center",
          staticStyle: {
            "width": "50px"
          }
        }, [_c('IAmLogoAirline', {
          attrs: {
            "airline": data.title.toUpperCase(),
            "height": 30
          }
        })], 1), _c('span', {
          staticClass: "flex-1"
        }, [_vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(data.title.toUpperCase())) ? _vm.$t("flight.airlines.".concat(data.title.toUpperCase())) : '') + " (" + _vm._s(_vm.$te("flight.sourceName.".concat(data.title.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(data.title.toUpperCase())) : data.title.toUpperCase()) + ") ")])])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('loading')) + " ")];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }