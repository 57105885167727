<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    id="one-way"
    class="w-100"
  >
    <div class="section-airport w-100 d-flex flex-wrap flex-md-nowrap align-items-center mb-2">
      <!-- ANCHOR From -->
      <b-card
        class="flex-grow-1 mb-1 mb-md-0 w-100 p-75"
        no-body
      >
        <div class="ml-1 text-body">
          {{ $t('flight.startFrom') }}
        </div>
        <SearchAirportSelect
          :data-prop.sync="searchData.flights[0].startPoint"
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
        />
      </b-card>

      <!-- ANCHOR switch -->
      <div class="switch_airport mx-auto mx-md-2 my-lg-1 flex-shrink-1">
        <b-button
          variant="primary"
          class="btn-icon rounded-circle p-2 p-lg-1 border"
          :disabled="!(searchData.flights[0].startPoint && searchData.flights[0].endPoint)"
          @click="$emit('swap-from-to')"
        >
          <IAmIcon
            icon="swap"
            class="d-flex-center text-white"
            size="20px"
          />
        </b-button>
      </div>

      <!-- ANCHOR TO -->
      <b-card
        class="flex-grow-1 mb-0 w-100 p-75"
        no-body
      >
        <div class="ml-1 text-body">
          {{ $t('flight.endAt') }}
        </div>
        <SearchAirportSelect
          :data-prop.sync="searchData.flights[0].endPoint"
          right
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
        />
      </b-card>
    </div>

    <b-card
      class="mb-2"
      no-body
    >
      <div :class="`d-flex flex-wrap flex-md-nowrap w-100 px-1 py-50 px-1 ${isMobileView ? 'gap-2 flex-column' : 'gap-1 flex-row d-flex-center'}`">
        <!-- ANCHOR Date -->
        <SearchDatePicker
          :date.sync="searchData.flights[0].departDate"
          class="flex-fill w-100 divider-after"
          :config="{ minDate: 'today' }"
          :flight="[searchData.flights[0].startPoint, searchData.flights[0].endPoint]"
        >
          <template #icon>
            <img
              src="@icons/airplane-up.svg"
              class="mr-50"
            >
          </template>
        </SearchDatePicker>

        <!-- ANCHOR Airlines -->
        <SearchSourceSelect
          style="min-width: 160px"
          :airlines.sync="searchData.airlines"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :permitted-carriers="searchData.permittedCarriers"
          class="divider-after flex-fill w-100"
        />

        <SearchCarriersSelect
          :carriers.sync="searchData.permittedCarriers"
          :is-disabled="isDisabledPermittedCariers"
          style="min-width: 160px"
          class="divider-after flex-fill w-100"
        />

        <!-- ANCHOR Passenger -->
        <SearchPassenger
          :adult.sync="searchData.adult"
          :child.sync="searchData.child"
          :infant.sync="searchData.infant"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          class="flex-fill w-100"
        />
      </div>
    </b-card>

    <b-card
      class="mb-2"
      no-body
    >
      <div :class="`d-flex flex-wrap flex-md-nowrap ${isMobileView ? 'gap-2 flex-column' : 'gap-1 flex-row d-flex-center'} w-100 px-1 py-1 py-md-50`">
        <!-- ANCHOR Direct flight select -->
        <SearchDirectFlightSelect
          style="min-width: 240px"
          :number-of-stop.sync="searchData.numberOfStop"
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="divider-after d-flex-center flex-fill"
        />

        <SearchClassFilter
          v-show="!searchData.isSearchClassBooking"
          :cabin-preference.sync="searchData.cabinPreference"
          :is-disabled="searchData.isSearchClassBooking || searchData.isSearchMonthlyCheapestFare"
          class="divider-after d-flex-center flex-fill w-100"
        />

        <PromoCodeInput
          :promo-codes.sync="searchData.promoCodes"
          :airlines.sync="searchData.airlines"
          class="divider-after d-flex-center flex-fill w-100"
        />

        <!-- ANCHOR Month Cheapest Fare -->
        <SearchMonthlyCheapestFare
          class="divider-after d-flex-center justify-content-between flex-fill"
          :is-search-monthly-cheapest-fare.sync="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
        />

        <!-- ANCHOR Class Booking -->
        <SearchClassBookingSelect
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
          class="flex-fill mr-md-5 ml-md-1"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    SearchAirportSelect: () => import('@flightv2/search/components/SearchAirportSelect.vue'),
    SearchSourceSelect: () => import('@flightv2/search/components/SearchSourceSelect.vue'),
    SearchPassenger: () => import('@flightv2/search/components/SearchPassenger.vue'),
    SearchDatePicker: () => import('@flightv2/search/components/SearchDatePicker.vue'),
    SearchDirectFlightSelect: () => import('@flightv2/search/components/SearchDirectFlightSelect.vue'),
    SearchMonthlyCheapestFare: () => import('@flightv2/search/components/SearchMonthlyCheapestFare.vue'),
    PromoCodeInput: () => import('@flightv2/search/components/PromoCodeInput.vue'),
    SearchClassBookingSelect: () => import('@flightv2/search/components/SearchClassBookingSelect.vue'),
    SearchClassFilter: () => import('@flightv2/search/components/SearchClassFilter.vue'),
    SearchCarriersSelect: () => import('@flightv2/search/components/SearchCarriersSelect.vue'),
  },
  props: {
    searchData: {
      type: Object,
      default: () => { },
    },
    isDisabledPermittedCariers: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
#one-way ::v-deep {
  .divider-after {
    &::after {
      content: "";
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #d3d3d3;
      height: 28px;
      margin: 0 1rem;

      @media (max-width: 768px) {
        content: none;
      }
    }
  }
}

.section-airport {
  position: relative;

  .switch_airport {
    @media (max-width: 767px) {
      position: absolute;
      z-index: 99;
      right: -50px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.item {
    background: #eee;
    width: 100%;
    padding: 15px;

    margin:0 10px;
    position: relative;
}
    .item::after {content: ' '; width: 2px; height: 50%; background-color: red; position: absolute; left: -10px; top: cal(50%);}
.item:first-child{
    margin-left: 0;

}
.item:first-child::after{
    display: none;

}

@media only screen and (max-width: 768px)  {
.container {display: flex; flex-direction: column}
.item::after {display: none}
    .item{ margin: 0;}

}
</style>
