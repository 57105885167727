<template>
  <div
    id="search-date-picker"
    class="d-flex-center"
    style="min-width: 102px;"
  >
    <slot name="icon" />
    <flat-pickr
      id="flat-pickr-date"
      ref="flatPickrRef"
      :value="dateValue"
      class="form-control border-0 px-50 text-body font-weight-bold text-subtitle"
      :config="{
        dateFormat: 'Y-m-d',
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
        shorthandCurrentMonth: true,
        disableMobile: true,
        ...config
      }"
      :events="['onDayCreate', 'onOpen', 'onMonthChange']"
      placeholder="Chọn ngày"
      @input="val => handleChange(val)"
      @on-day-create="listenToOnDayCreateEvent"
    />
    <!-- @on-open="onOpenHandle" -->
    <!-- @on-month-change="onMonthChangeHandle" -->
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { toRefs, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/airbnb.css'
import { LunarDate } from 'vietnamese-lunar-calendar'
import { sortBy } from 'lodash'

// import { formatDateMonth, kFormatter } from '@core/utils/filter'
import { apiBooking } from '@/api/'
import { specialDayOfVietnam } from '@/constants/selectOptions'
import { convertISODateTime } from '@/@core/utils/filter'
// import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    flatPickr,
  },
  props: {
    date: {
      type: [String, Date],
      default: '',
    },
    config: {
      type: Object,
      default: () => { },
    },
    flight: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()

    // const flatPickrRef = ref()
    const dateValue = toRefs(props).date
    function handleChange(val) {
      const chooseDate = val
      const dateProp = convertISODateTime(new Date(dateValue.value)).dateFilter
      if (!chooseDate) {
        toastError('messagesList["Invalid date, please try again"]')
      }
      if (chooseDate && dateProp !== chooseDate) {
        emit('update:date', chooseDate)
        emit('input', chooseDate)
      }
    }

    // const { fetchCheapestFare } = useBookingHandle()
    const cheapestFare = ref([])

    function getMinTotalFare(array) {
      return sortBy(
        array,
        i => i.netFare,
      )[0]
    }
    const flightArray = toRefs(props).flight

    // eslint-disable-next-line no-unused-vars
    function fetchCheapestFareOfMonth(opts = {}) {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + 1)

      const options = {
        month: (`0${(currentDate.getMonth() + 1).toString()}`).slice(-2),
        year: (currentDate.getFullYear()).toString(),
        from: flightArray.value[0].iata || flightArray.value[0],
        to: flightArray.value[1].iata || flightArray.value[1],
        ...opts,
      }
      return new Promise((resolve, reject) => {
        apiBooking.fetchCheapestFare({
          departure: options.from,
          arrival: options.to,
          startDate: `${options.year}-${options.month}-01`,
          endDate: `${options.year}-${options.month}-31`,
        })
          .then(res => {
            const dataToPush = res.data.map(item => {
              const newCheap = getMinTotalFare(item.cheapestFare)
              return {
                ...item,
                cheapestFare: newCheap.netFare,
              }
            })
            cheapestFare.value = dataToPush
            resolve(res)
          })
          .catch(error => {
            reject(error)
            toastError({
              title: 'Lỗi lấy giá rẻ theo ngày!',
            })
          })
      })
    }
    // eslint-disable-next-line no-unused-vars
    // function onOpenHandle(selectedDates, dateStr, instance) {
    //   fetchCheapestFareOfMonth().then(() => {
    //     instance.redraw()
    //   })
    // }

    // function onMonthChangeHandle(...value) {
    //   const instance = value[2]
    //   fetchCheapestFareOfMonth({
    //     month: (`0${(value[2].currentMonth + 1).toString()}`).slice(-2),
    //     year: value[2].currentYear,
    //   }).then(() => {
    //     instance.redraw()
    //   })
    // }

    function listenToOnDayCreateEvent(...val) {
      const solarDate = {
        year: new Date(val[3].dateObj).getFullYear(),
        month: new Date(val[3].dateObj).getMonth() + 1,
        day: new Date(val[3].dateObj).getDate(),
      }
      const nowDay = Number(val[2].now.getDate())
      const nowMonth = Number(val[2].now.getMonth()) + 1
      const currentMonth = val[2].currentMonth + 1
      const lunarDate = new LunarDate(solarDate.year, solarDate.month, solarDate.day)
      // console.log(new Date(val[3].dateObj), solarDate, lunarDate)
      // console.log('listenToOnDayCreateEvent', new Date(val[3].dateObj).getMonth())
      const oldValue = val[3].innerHTML
      // let price = ''
      // if (cheapestFare.value.length) {
      //   // eslint-disable-next-line arrow-body-style
      //   const priceDay = cheapestFare.value.find(item => {
      //     return formatDateMonth(new Date(item.date)) === formatDateMonth(new Date(val[3].dateObj))
      //   })
      //   if (priceDay) {
      //     price = kFormatter(priceDay.cheapestFare)
      //   }
      // }
      const isSpecialDay = specialDayOfVietnam.find(item => {
        if (item.type === 'solar') {
          return item.date === Number(solarDate.day) && item.month === Number(solarDate.month)
        }
        if (item.type === 'lunar') {
          return item.date === lunarDate?.date && item.month === lunarDate?.month
        }
        return false
      })

      val[3].innerHTML = `
      <div class="${solarDate.month !== currentMonth || (solarDate.day < nowDay && solarDate.month === nowMonth) ? 'opacity-50' : ''}">
        <div class="day-value ${isSpecialDay ? 'text-danger' : ''}">${oldValue}</div>
        <div style="
          font-size: 10px;
          display: block;
          position: absolute;
          bottom: -10px;
          right: 4px;
          ${lunarDate.date === 1 || ((Number(oldValue) === 1 && Number(solarDate.month) === currentMonth)) || isSpecialDay ? 'color: red' : ''}"
        >${lunarDate.date}${lunarDate.date === 1 || (Number(oldValue) === 1 && Number(solarDate.month) === currentMonth) || isSpecialDay ? `/${lunarDate.month}` : ''}</div>
      </div>
      `
      // Ẩn giá rẻ đi - yc aCông
      // val[3].innerHTML = `
      // <div>
      //   <div class="day-value">${oldValue}</div>
      //   <div
      //     style="
      //     width: 100%;
      //       font-size: 12px;
      //       display: block;
      //       position: absolute;
      //       bottom: -10px;
      //       font-weight: 400;
      //       "
      //   >${price}</div>
      //   <div style="
      //     font-size: 8px;
      //     display: block;
      //     position: absolute;
      //     bottom: 8px;
      //     right: 4px;
      //     ${lunarDate.date === 1 || (Number(oldValue) === 1 && Number(solarDate.month) === currentMonth) ? 'color: red' : ''}"
      //   >${lunarDate.date}${lunarDate.date === 1 || (Number(oldValue) === 1 && Number(solarDate.month) === currentMonth) ? `/${lunarDate.month}` : ''}</div>
      // </div>
      // `
    }

    return {
      listenToOnDayCreateEvent,
      handleChange,
      dateValue,
      Vietnamese,
      // flatPickrRef,
      // onOpenHandle,
      // onMonthChangeHandle,
    }
  },
}

</script>

<style lang="scss">
span.flatpickr-day {
  height: 45px;

  .day-value {
    transform: translate(2px, -2px);
    font-size: 20px
  }
}

.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.selected:hover {
  border-radius: 4px !important;
}

span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-bottom-color: #3DA5DE;

}

span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border-color: #3DA5DE;
}
</style>
