var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "search-airport"
    }
  }, [_c('v-select', {
    ref: "vSelectRef",
    staticClass: "w-100",
    staticStyle: {
      "min-width": "200px"
    },
    attrs: {
      "value": _vm.getValueProp,
      "append-to-body": _vm.appendToBody,
      "calculate-position": _vm.withPopper,
      "filterable": false,
      "clearable": false,
      "get-option-label": function getOptionLabel(searchData) {
        return searchData;
      },
      "options": _vm.airportOptions
    },
    on: {
      "open": _vm.handleOpenAirport,
      "search": _vm.handleSearchAirport,
      "option:selected": _vm.handleChooseAirport
    },
    scopedSlots: _vm._u([{
      key: "no-options",
      fn: function fn(_ref) {
        var search = _ref.search,
          searching = _ref.searching;
        return [_vm.isLoadingSearchAirport ? [_c('b-spinner', {
          attrs: {
            "variant": "warning",
            "label": "Text Centered",
            "small": "",
            "tag": "span"
          }
        }), _c('span', {
          staticClass: "pl-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.loadingAirport')) + " ")])] : searching ? [_vm._v(" " + _vm._s(_vm.$t('flight.noResultFound')) + " "), _c('b', [_vm._v(_vm._s(search))])] : [!(_vm.showDefaultAirport || !search) ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")]) : _c('div', {
          staticClass: "d-none"
        })]];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-title text-warning"
        }, [_vm._v(" " + _vm._s(data.city || data.name.split(', ')[0]) + " ")])];
      }
    }, {
      key: "list-header",
      fn: function fn(_ref2) {
        var search = _ref2.search,
          searching = _ref2.searching;
        return [(_vm.showDefaultAirport || !search) && !searching ? _c('b-card', {
          staticClass: "h-25",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-tabs', {
          attrs: {
            "vertical": "",
            "pills": "",
            "nav-wrapper-class": "nav-vertical p-0",
            "content-class": "p-0"
          }
        }, _vm._l(_vm.airportOptionsDefault.groups, function (group, index) {
          return _c('b-tab', {
            key: index,
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "text-uppercase text-left"
                }, [_vm._v(" " + _vm._s(group.displayName) + " ")])];
              },
              proxy: true
            }], null, true)
          }, [_c('vue-perfect-scrollbar', {
            staticClass: "ps-customizer-area scroll-area",
            staticStyle: {
              "max-height": "28em"
            },
            attrs: {
              "settings": {
                maxScrollbarLength: 60,
                wheelPropagation: false
              }
            }
          }, _vm._l(group.airports, function (item, i) {
            return _c('div', {
              key: i
            }, [_c('b-button', {
              staticClass: "w-100 text-left text-body",
              attrs: {
                "variant": "flat-warning"
              },
              on: {
                "click": function click($event) {
                  return _vm.handleChooseAirport(item, true);
                }
              }
            }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
          }), 0)], 1);
        }), 1)], 1) : _vm._e()];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [!_vm.showDefaultAirport ? _c('div', [_c('div', {
          staticClass: "d-flex justify-content-start text-truncate"
        }, [_c('strong', [_vm._v(_vm._s(data.city))]), _c('strong', {
          class: "".concat(data.iata !== _vm.valueProp.iata ? 'text-info' : '', " text-truncate font-weight-bold")
        }, [_vm._v(_vm._s(data.iata))])]), _c('div', {
          staticClass: "d-flex justify-content-between text-truncate"
        }, [_c('span', {
          staticClass: "text-truncate"
        }, [_vm._v(_vm._s(data.name) + ", " + _vm._s(data.country))])])]) : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }