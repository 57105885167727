<template>
  <b-modal
    id="suggest-shop-modal"
    shadow
    size="lg"
    centered
    no-close-on-backdrop
    no-close-on-esc
    header-bg-variant="light-info"
    hide-footer
    :title="$t('suggestShopVJ')"
  >
    <b-row no-gutters>
      <b-col>
        <IAmOverlay :loading="loadingShopVJ">
          <b-table
            ref="refResellTicket"
            bordered
            hover
            no-border-collapse
            sticky-header
            responsive
            show-empty
            primary-key="id"
            style="max-height: 70vh"
            class="position-relative bg-white"
            table-class="table-resell-ticket"
            :items="searchResellTickets"
            :fields="shopColumns"
            :empty-text="$t('noRecordFund')"
          >
            <!-- ANCHOR Header -->
            <template
              v-for="column in shopColumns"
              #[`head(${column.key})`]="data"
            >
              <div
                :key="column.label"
                class="text-dark text-nowrap text-center"
              >
                <template>
                  {{ $t(`resellTicket.columns.${data.label}`) }}
                </template>
              </div>
            </template>

            <template
              v-for="column in shopColumns"
              #[`cell(${column.key})`]="{ item }"
            >
              <div
                :key="column.key"
                class="text-nowrap"
              >
                <!-- :class="{ 'text-right': typeof item[column.key] === 'number' }" -->
                {{ item[column.key] }}
              </div>
            </template>

            <!-- ANCHOR stt. -->
            <template #cell(stt)="data">
              <div class="text-center">
                {{ data.index + 1 }}
              </div>
            </template>

            <!-- ANCHOR price. -->
            <template #cell(price)="{ item }">
              <div v-if="item.price">
                {{ formatVnCurrency(item.price) }}
              </div>
              <div
                v-else
                class="d-flex-center"
              >
                <div class="badge badge-info badge-glow p-50">
                  {{ $t('resellTicket.agreement') }}
                </div>
              </div>
            </template>

            <!-- ANCHOR phone. -->
            <template #cell(phone)="{ item }">
              <div>
                <a
                  v-b-tooltip.hover.v-dark.window="`${$t('resellTicket.btn.call')}`"
                  :href="'tel:' + item.phone"
                  class="hover-underline"
                >
                  {{ (item.phone) }}
                </a>
              </div>
            </template>

            <!-- ANCHOR expiredDate. -->
            <template #cell(expiredDate)="{ item }">
              <span> {{
                isDateGreaterThanOrEqualToToday(item.expiredDate)
                  ? convertISODateTime(item.expiredDate).date
                  : $t('resellTicket.expired')
              }}
              </span>
            </template>

            <!-- ANCHOR note. -->
            <template #cell(note)="{ item }">
              <div class="min-width-200 text-break text-justify">
                {{ item.note }}
              </div>
            </template>

            <!-- ANCHOR actions. -->
            <template #cell(actions)="{ item }">
              <div class="d-flex gap-1 align-items-center">
                <b-button
                  v-if="!item.reported && isRoleF2"
                  variant="warning"
                  class="p-50 rounded text-nowrap"
                  @click="createReport(item.id)"
                >
                  {{ $t('resellTicket.btn.report') }}
                </b-button>
              </div>
            </template>
          </b-table>
        </IAmOverlay>
      </b-col>
    </b-row>
    <CreateReportResellTicketModal
      :id="resellTicketId"
      @refresh="searchResellTicket"
    />
  </b-modal>
</template>

<script>
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BCol,
  BRow,
  BTable,
  VBToggle,
  VBTooltip,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import Ripple from 'vue-ripple-directive'

import store from '@/store'
import { convertISODateTime, formatVnCurrency } from '@/@core/utils/filter'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BModal,
    BButton,
    BFormCheckbox,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    CreateReportResellTicketModal: () => import('@saleReport/resell-ticket/CreateReportResellTicketModal.vue'),
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    passengerDataToAdd: {
      type: Array,
      required: true,
    },
    airlineCode: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const meData = computed(() => store.getters['userStore/getMeData'])
    const { passengerDataToAdd, airlineCode } = toRefs(props)
    const {
      tableColumns,
      searchResellTickets,
      filterSearchResellTicket,
      searchResellTicket,
      airlineSupports,
    } = useResellTicketHandle()

    const shopColumns = computed(() => tableColumns.value.filter(
      column => ![
        'notePrivate',
        'isActive',
        'expiredDate',
        'ticketNumber',
        'pnr',
        'type',
        'publishDate',
        'source',
        'airlineCode',
      ].includes(column.key),
    ))
    const loadingShopVJ = ref(false)

    function getAirline(code) {
      if (!code) return ''
      const rs = airlineSupports.value.find(e => e.code === code)
      if (!rs) return ''
      return `${rs?.name} (${rs?.code})`
    }

    const resellTicketId = ref(null)
    const isChild = ref(false)
    const resellTicket = ref(null)
    function createReport(id) {
      resellTicketId.value = id
      this.$bvModal.show('report-resell-ticket-modal')
    }

    function detailReport(item) {
      if (!isRoleF1.value) return
      resellTicket.value = item
      isChild.value = isRoleF1.value || [item?.agencySellCode, ...(item?.parentAgencySellCode ? [item?.parentAgencySellCode] : [])].includes(meData.value?.agency.agencyCode)
      this.$bvModal.show('detail-report-resell-ticket-modal')
    }
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const debouncedFetch = debounce(searchResellTicket, 1200)
    watch(passengerDataToAdd, val => {
      if (!isRoleF3.value) {
        const search = val
          .filter(e => e.lastName && e.firstName)
          .map(e => `${e.lastName} ${e.firstName}`.trim())
          .join(',')
        if (search) {
          filterSearchResellTicket.value.paxNames = search
          filterSearchResellTicket.value.airlineCode = airlineCode
          filterSearchResellTicket.value.size = 60
          debouncedFetch()
        }
      }
    }, { deep: true })

    watch(searchResellTickets, val => {
      if (val.length > 0) emit('set-visible', true)
      else emit('set-visible', false)
    }, { deep: true })
    return {
      isRoleF1,
      isRoleF2,
      meData,
      resellTicketId,
      loadingShopVJ,
      shopColumns,
      searchResellTickets,
      searchResellTicket,
      createReport,
      detailReport,
      getAirline,
      convertISODateTime,
      formatVnCurrency,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.border-transparent {
  border-color: transparent !important;
  background-color: transparent !important;
}
</style>
