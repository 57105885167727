<template>
  <b-modal
    id="modal-customer-service-fee"
    :title="$t('flight.setServiceFee')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    @show="openModalHandle"
  >
    <b-tabs
      pills
      lazy
    >
      <!-- custom sFee -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="EditIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('flight.setServiceFee') }}</span>
        </template>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="amount-adult">
              <template #label>
                <h5>{{ $t('fee.adult') }}</h5>
              </template>
              <IAmInputMoney
                id="amount-adult"
                :value-money.sync="customFeeServiceDataToModifyTemp.adultAmount"
                class="flex-grow-1"
                @input="val => handleInputAdultAmount(val)"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="amount-child">
              <template #label>
                <h5>{{ $t('fee.children') }}</h5>
              </template>
              <IAmInputMoney
                id="amount-child"
                :value-money.sync="customFeeServiceDataToModifyTemp.childAmount"
                class="flex-grow-1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="amount-infant">
              <template #label>
                <h5>{{ $t('fee.infant') }}</h5>
              </template>
              <IAmInputMoney
                id="amount-infant"
                :value-money.sync="customFeeServiceDataToModifyTemp.infantAmount"
                class="flex-grow-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="float-right my-50">
          <b-button
            variant="info"
            @click="modifyCustomServiceFee"
          >
            {{ $t('edit') }}
          </b-button>
        </div>
      </b-tab>

      <!-- use profile -->
      <b-tab :active="isActiveProfile">
        <template #title>
          <feather-icon
            icon="ToolIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('flight.useProfileServiceFee') }}</span>
        </template>
        <div class="pb-1">
          <IAmOverlay :loading="!employeeProfileList">
            <app-collapse
              accordion
              type="margin"
            >
              <app-collapse-item
                v-for="(employeeProfileItem, employeeProfileIndex) in employeeProfileList"
                :key="employeeProfileIndex"
                title
              >
                <template #header>
                  <div class="d-flex-between w-100 mr-2">
                    <span class="text-heading-4">{{ employeeProfileItem.name }}
                      <BBadge
                        v-if="employeeProfileItem.isDefault"
                        variant="success"
                        class="badge-glow round pills ml-50"
                      >
                        {{ $t('fee.default') }}
                      </BBadge>
                    </span>
                    <em
                      v-if="selectedProfile ? selectedProfile.id === employeeProfileItem.id : (isUseCustom === true ? false : employeeProfileItem.isDefault)"
                      class="text-heading-5"
                    >
                      {{ $t('using') }}
                    </em>
                    <b-button
                      v-else
                      variant="flat-info"
                      class="text-heading-5 py-25"
                      pill
                      @click.prevent.stop="handleApplyProfile(employeeProfileItem)"
                    >
                      {{ $t('apply') }}
                    </b-button>
                  </div>
                </template>

                <div>
                  <BTable
                    :fields="tableColumns"
                    striped
                    bordered
                    hover
                    responsive
                    :empty-text="$t('noMatchingResult')"
                    :items="employeeProfileItem.serviceFeeConfigs"
                  >
                    <template
                      v-for="column in tableColumns"
                      #[`head(${column.key})`]="data"
                    >
                      <div
                        :key="column.label"
                        class="text-dark text-nowrap"
                        :class="{
                          'text-right': ['adultAmount', 'childAmount', 'infantAmount'].includes(data.label),
                          'text-center': ['action'].includes(data.label)
                        }"
                      >
                        {{ data.label ? $t(`fee.columns.${data.label}`) : '' }}
                      </div>
                    </template>
                    <template #cell(feeType)="data">
                      {{ $t(`fee.${data.item.feeType}`) }}
                    </template>
                    <template #cell(ticketType)="data">
                      {{ $t(`fee.${data.item.ticketType}`) }}
                    </template>
                    <template #cell(adultAmount)="data">
                      <div class="text-right">
                        {{ formatCurrency(data.item.adultAmount) }}
                      </div>
                    </template>
                    <template #cell(childAmount)="data">
                      <div class="text-right">
                        {{ formatCurrency(data.item.childAmount) }}
                      </div>
                    </template>
                    <template #cell(infantAmount)="data">
                      <div class="text-right">
                        {{ formatCurrency(data.item.infantAmount) }}
                      </div>
                    </template>
                  </BTable>
                </div>
              </app-collapse-item>
            </app-collapse>
          </IAmOverlay>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import {
  BFormGroup, BRow, BCol, BTab, BTabs, BButton, BTable, BBadge,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'

import { formatCurrency } from '@/@core/utils/filter'
import store from '@/store'
import { apiEmployee } from '@/api'

import useToast from '@useToast'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BTab,
    BTabs,
    BButton,
    BTable,
    BBadge,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    AppCollapse: () => import('@/@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@/@core/components/app-collapse/AppCollapseItem.vue'),
  },
  setup() {
    const { toastSuccess } = useToast()
    const customFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])
    const progressValue = computed(() => store.getters['app-flight-v2/getProgressValue'])
    const employeeId = computed(() => store.getters['userStore/getMeDataId'])

    const blankCustomFeeServiceDataToModify = {
      adultAmount: customFeeServiceDataToModify.value?.adultAmount ?? null,
      childAmount: customFeeServiceDataToModify.value?.childAmount ?? null,
      infantAmount: customFeeServiceDataToModify.value?.infantAmount ?? null,
    }

    const customFeeServiceDataToModifyTemp = ref(cloneDeep(blankCustomFeeServiceDataToModify))

    const resetCustomFeeService = () => {
      customFeeServiceDataToModifyTemp.value = cloneDeep(customFeeServiceDataToModify.value)
    }
    watch(progressValue, () => {
      if (!progressValue.value) {
        resetCustomFeeService()
      }
    })

    function modifyCustomServiceFee() {
      store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(async () => {
        await store.dispatch('app-flight-v2/setCustomFeeServiceDataToModify', customFeeServiceDataToModifyTemp.value)
        toastSuccess('flight.applyProfileServiceFeeSuccessful')
        clearTimeout(timeoutId)
        this.$bvModal.hide('modal-customer-service-fee')
        store.dispatch('app-flight-v2/setLoading', false)
      }, 100)
    }

    function handleInputAdultAmount(val) {
      customFeeServiceDataToModifyTemp.value.childAmount = val
    }

    const employeeProfileList = ref()

    const isUseCustom = computed(() => {
      const result = !!(
        !isNil(customFeeServiceDataToModifyTemp.value.adultAmount)
        || !isNil(customFeeServiceDataToModifyTemp.value.childAmount)
        || !isNil(customFeeServiceDataToModifyTemp.value.infantAmount)
      )
      return result
    })

    async function openModalHandle() {
      if (!employeeProfileList.value) {
        const { data: res } = await apiEmployee.getEmployeeProfile(employeeId.value)
        employeeProfileList.value = res
      }
      customFeeServiceDataToModifyTemp.value = cloneDeep(customFeeServiceDataToModify.value)
    }

    const tableColumns = ref([
      { label: 'airline', key: 'airline' },
      { label: 'feeType', key: 'feeType' },
      { label: 'ticketType', key: 'ticketType' },
      { label: 'adultAmount', key: 'adultAmount' },
      { label: 'childAmount', key: 'childAmount' },
      { label: 'infantAmount', key: 'infantAmount' },
    ])

    const selectedProfile = computed(() => store.getters['app-flight-v2/getSelectedProfileFeeService'])

    function handleApplyProfile(employeeProfile) {
      store.dispatch('app-flight-v2/setLoading', true)
      resetCustomFeeService()
      const timeoutId = setTimeout(() => {
        store.dispatch('app-flight-v2/setSelectedProfileFeeService', employeeProfile)
        toastSuccess('flight.applyProfileServiceFeeSuccessful')
        clearTimeout(timeoutId)
        this.$bvModal.hide('modal-customer-service-fee')
        store.dispatch('app-flight-v2/setLoading', false)
      }, 100)
    }

    const isActiveProfile = computed(() => (!!selectedProfile.value || !isUseCustom.value))

    return {
      // customer service fee
      customFeeServiceDataToModifyTemp,
      modifyCustomServiceFee,
      handleInputAdultAmount,

      openModalHandle,
      employeeProfileList,
      tableColumns,
      formatCurrency,
      handleApplyProfile,
      selectedProfile,
      isActiveProfile,
      isUseCustom,
    }
  },
}
</script>
