var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "dropdown-type"
    }
  }, [_c('b-dropdown', {
    staticClass: "w-100 justify-content-start",
    attrs: {
      "variant": "flat-dark",
      "toggle-class": "py-75 px-1",
      "boundary": "window"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-inline-flex align-items-center text-secondary"
        }, [_vm.type === 'OW' ? _c('b-img', {
          staticClass: "airlines-logo px-0 mr-25",
          staticStyle: {
            "height": "18px"
          },
          attrs: {
            "blank-color": "#ccc",
            "src": require("@icons/airplane.svg")
          }
        }) : _vm._e(), _vm.type === 'RT' ? _c('b-img', {
          staticClass: "airlines-logo px-0 mr-25",
          staticStyle: {
            "height": "18px"
          },
          attrs: {
            "blank-color": "#ccc",
            "src": require("@icons/airplane.svg")
          }
        }) : _vm._e(), _c('label', {
          staticClass: "text-dark-2 mb-0"
        }, [_vm._v(_vm._s(_vm.type === 'OW' ? _vm.$t('flight.OW') : _vm.type === 'RT' ? _vm.$t('flight.RT') : _vm.$t('flight.MC')))])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:type', 'OW');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "airlines-logo px-0 mr-25",
    staticStyle: {
      "height": "18px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@icons/airplane.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.OW')) + " ")], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:type', 'RT');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "airlines-logo px-0 mr-25",
    staticStyle: {
      "height": "18px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@icons/airplane.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.RT')) + " ")], 1)]), _vm.showTypeMC ? _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:type', 'MC');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.MC')) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }