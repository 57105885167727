<template>
  <div v-show="!isMobileView">
    <div
      class="d-flex-center gap-2 w-100 p-50 rounded"
      style="background: #cccccc50;"
    >
      <!-- ANCHOR CARD INFO -->
      <b-card
        class="rounded mb-0 flex-grow-1 d-flex-center flex-row justify-content-around flex-wrap flex-xl-nowrap px-50"
        no-body
      >
        <SearchSwitchTypeDropdown
          :type.sync="reqSearchFlight.type"
          :show-type-m-c="false"
          class="divider-after"
        />

        <div class="divider-after flex-grow-1 d-flex-center">
          <ResultSearchAirportSelect
            :data-prop.sync="reqSearchFlight.flights[0].startPoint"
            class="flex-grow-1"
          />

          <div class="mx-md-50 d-flex-center">
            <b-button
              variant="white"
              class="btn-icon rounded-circle p-50"
              @click="swapHandle"
            >
              <img
                src="@icons/swap-horizontal.svg"
                alt="Swap"
              >
            </b-button>
          </div>

          <ResultSearchAirportSelect
            :data-prop.sync="reqSearchFlight.flights[0].endPoint"
            right
            class="flex-grow-1"
          />
        </div>

        <!-- ANCHOR DATE -->
        <div
          class="d-flex-center"
          :class="isMobileView ? 'flex-wrap' : ''"
        >
          <b-col
            :cols="reqSearchFlight.type === 'RT' ? 6 : 12"
            class="d-flex align-items-center px-25 px-md-50 divider-after"
          >
            <SearchDatePicker
              :date.sync="reqSearchFlight.flights[0].departDate"
              :config="{ minDate: 'today' }"
              :flight="[reqSearchFlight.flights[0].startPoint, reqSearchFlight.flights[0].endPoint]"
              @input="handleDepartDate"
            >
              <template #icon>
                <img
                  src="@icons/airplane-up.svg"
                  class="mr-25"
                >
              </template>
            </SearchDatePicker>
          </b-col>

          <b-col
            v-if="reqSearchFlight.type === 'RT'"
            cols="6"
            class="d-flex align-items-center px-25 px-md-50 divider-after"
          >
            <SearchDatePicker
              :date.sync="reqSearchFlight.flights[0].returnDate"
              :config="{ minDate: reqSearchFlight.flights[0].departDate }"
              :flight="[reqSearchFlight.flights[0].endPoint, reqSearchFlight.flights[0].startPoint]"
            >
              <template #icon>
                <img
                  src="@icons/airplane-down.svg"
                  class="mr-25"
                >
              </template>
            </SearchDatePicker>
          </b-col>
        </div>

        <!-- ANCHOR Passenger -->
        <SearchPassenger
          id="result-search-header"
          class="divider-after d-flex-center"
          :adult.sync="reqSearchFlight.adult"
          :child.sync="reqSearchFlight.child"
          :infant.sync="reqSearchFlight.infant"
          :id-no-of-passenger="'result-search-header'"
          :show-text-passenger="this.$store.state.app.windowWidth > 1400 || this.$store.state.app.windowWidth < 1200"
        />

        <!-- ANCHOR Button Show Modal Search -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="warning"
          :disabled="isDisable || (!isClassBooking && !isMonthlyCheapFare && isSearching)"
          class="btn-icon p-50 text-nowrap rounded-circle"
          @click="handleShowModalResultSearch"
        >
          <feather-icon
            icon="MoreHorizontalIcon"
            size="18"
            class="ico-bold"
          />
        </b-button>
      </b-card>
      <!-- ANCHOR BUTTON SEARCH -->
      <div>
        <b-button
          v-ripple.400
          :disabled="isDisable || (!isClassBooking && !isMonthlyCheapFare && isSearching)"
          class="btn-gradient p-75 text-truncate rounded"
          @click="handleSearchButton(reqSearchFlight, true, true)"
        >
          <div class="d-flex align-items-center">
            <img
              src="@icons/search.svg"
              alt="Search"
              class="ico-bold"
            >
          </div>
        </b-button>
      </div>
    </div>

    <ModalResultSearch
      @submit="handleSearchButton"
      @submitClassBooking="submitClassBooking"
      @update-search-data="updateSearchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BCol,
} from 'bootstrap-vue'
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import { cloneDeep, isEmpty } from 'lodash'
import Ripple from 'vue-ripple-directive'

import store from '@/store'

import ResultSearchAirportSelect from '@flightv2/search/components/SearchAirportSelect_result.vue'
import SearchSwitchTypeDropdown from '@flightv2/search/components/SearchSwitchTypeDropdown.vue'
import SearchDatePicker from '@flightv2/search/components/SearchDatePicker.vue'
import SearchPassenger from '@flightv2/search/components/SearchPassenger.vue'
import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

import ModalResultSearch from './ModalResultSearch.vue'

export default {
  components: {
    BCard,
    BButton,
    BCol,

    SearchSwitchTypeDropdown,
    SearchDatePicker,
    SearchPassenger,
    ResultSearchAirportSelect,

    ModalResultSearch,
  },
  directives: {
    Ripple,
  },
  props: {
    defaultSearchData: {
      type: Object,
      default: () => {},
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isClassBooking: {
      type: Boolean,
      default: false,
    },
    isMonthlyCheapFare: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()
    const { isClassBooking, isMonthlyCheapFare } = toRefs(props)
    const {
      searchFlightv2,
      fetchAirportGroup,
      processValue,
      getLoading,
      statusJobDone,
    } = useBookingHandle()
    if (isEmpty(store.getters['app-flightv2/getAirportGroup'])) { fetchAirportGroup() }

    const isSearching = computed(() => !!(getLoading.value || processValue.value || processValue.value === 0 || !statusJobDone.value))

    const reqSearchFlight = ref(cloneDeep(props.defaultSearchData))

    function swapHandle() {
      const temp = reqSearchFlight.value.flights[0].startPoint
      reqSearchFlight.value.flights[0].startPoint = reqSearchFlight.value.flights[0].endPoint
      reqSearchFlight.value.flights[0].endPoint = temp
    }

    // use in modal
    const updateSearchData = async () => {
      reqSearchFlight.value = null
      const storageData = localStorage.getItem('searchFlight')
      if (storageData) {
        reqSearchFlight.value = cloneDeep(JSON.parse(storageData))
      } else {
        reqSearchFlight.value = cloneDeep(props.defaultSearchData)
      }
    }

    function handleShowModalResultSearch() {
      this.$bvModal.show('modal-result-flightv2-search')
    }

    function showErrorEmptyDate() {
      toastError({
        title: 'Vui lòng chọn ngày về!',
      })
    }

    function handleSearchButton(reqSearch, setData = true, isShowModalMC = false) {
      const data = cloneDeep(reqSearch)

      if (['MC'].includes(data.type) && isShowModalMC) {
        toastError({ title: 'Vui lòng kiểm tra thông tin tìm kiếm nhiều chặng!' })
        this.$bvModal.show('modal-result-flightv2-search')
      } else {
        if (setData) localStorage.setItem('searchFlight', JSON.stringify(data))

        switch (true) {
          case isClassBooking.value || data.isSearchMonthlyCheapestFare:
            if (['RT'].includes(data.type) && !data.flights[0].returnDate) {
              showErrorEmptyDate()
            } else {
              emit('handleSearch', data)
            }
            break

          case ['RT'].includes(data.type) && !data.flights[0].returnDate:
            showErrorEmptyDate()
            break

          default:
            searchFlightv2(data)
            break
        }
      }
    }

    function handleDepartDate(val) {
      const departDate = new Date(val)
      const returnDate = new Date(reqSearchFlight.value.flights[0].returnDate)

      if (departDate > returnDate || !reqSearchFlight.value.flights[0].returnDate) {
        const newReturnDate = new Date(Date.UTC(departDate.getFullYear(), departDate.getMonth(), departDate.getDate() + 3))
        reqSearchFlight.value.flights[0].returnDate = newReturnDate.toISOString().split('T')[0]
      }
    }

    watch(reqSearchFlight, val => {
      if (val.type === 'RT' && !val.flights[0].returnDate) {
        handleDepartDate(val.flights[0].departDate)
      }
    }, { deep: true })

    const showDateSelect = ref(false)

    function submitClassBooking(data) {
      if (data.type === 'RT' && !data.flights[0].returnDate) {
        showErrorEmptyDate()
      } else {
        emit('handleSearch', data)
      }
    }

    return {
      handleShowModalResultSearch,
      submitClassBooking,
      reqSearchFlight,
      swapHandle,
      handleSearchButton,
      handleDepartDate,
      showDateSelect,

      updateSearchData,
      isSearching,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.divider-after {
  display: flex;
  &::after {
    content: "";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #d3d3d3;
    height: 30px;
    margin: auto 1rem;

    @media (max-width: 768px) {
      content: none;
    }
  }
}
</style>
