<template>
  <div id="dropdown-type">
    <b-dropdown
      variant="flat-dark"
      class="w-100 justify-content-start"
      toggle-class="py-75 px-1"
      boundary="window"
    >
      <template #button-content>
        <div class="d-inline-flex align-items-center text-secondary">
          <b-img
            v-if="type === 'OW'"
            class="airlines-logo px-0 mr-25"
            blank-color="#ccc"
            style="height: 18px;"
            :src="require(`@icons/airplane.svg`)"
          />
          <b-img
            v-if="type === 'RT'"
            class="airlines-logo px-0 mr-25"
            blank-color="#ccc"
            style="height: 18px;"
            :src="require(`@icons/airplane.svg`)"
          />
          <label class="text-dark-2 mb-0">{{ type === 'OW' ? $t('flight.OW') : type === 'RT' ? $t('flight.RT') : $t('flight.MC') }}</label>
        </div>
      </template>
      <b-dropdown-item
        @click="$emit('update:type', 'OW')"
      >
        <div class="d-flex-center">
          <b-img
            class="airlines-logo px-0 mr-25"
            blank-color="#ccc"
            style="height: 18px;"
            :src="require(`@icons/airplane.svg`)"
          />
          {{ $t('flight.OW') }}
        </div>
      </b-dropdown-item>
      <b-dropdown-item
        @click="$emit('update:type', 'RT')"
      >
        <div class="d-flex-center">
          <b-img
            class="airlines-logo px-0 mr-25"
            blank-color="#ccc"
            style="height: 18px;"
            :src="require(`@icons/airplane.svg`)"
          />
          {{ $t('flight.RT') }}
        </div>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="showTypeMC"
        @click="$emit('update:type', 'MC')"
      >
        {{ $t('flight.MC') }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { BDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BDropdown, BDropdownItem, BImg,
  },
  props: {
    type: {
      type: String,
      default: 'OW',
    },
    showTypeMC: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
#dropdown-type ::v-deep {
  .dropdown-toggle {
    &::after {
      top: -2px;
    }
  }
  .dropdown-menu {
    z-index: 99;
  }
}
</style>
