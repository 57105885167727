<template>
  <div id="search-airport">
    <v-select
      ref="vSelectRef"
      :value="getValueProp"
      :append-to-body="appendToBody"
      :calculate-position="withPopper"
      :filterable="false"
      :clearable="false"
      class="w-100"
      style="min-width: 200px;"
      :get-option-label="(searchData) => searchData"
      :options="airportOptions"
      @open="handleOpenAirport"
      @search="handleSearchAirport"
      @option:selected="handleChooseAirport"
    >
      <template #no-options="{ search, searching }">
        <template v-if="isLoadingSearchAirport">
          <b-spinner
            variant="warning"
            label="Text Centered"
            small
            tag="span"
          />
          <span class="pl-1"> {{ $t('flight.loadingAirport') }} </span>
        </template>
        <template v-else-if="searching">
          {{ $t('flight.noResultFound') }} <b>{{ search }}</b>
        </template>
        <template v-else>
          <div v-if="!((showDefaultAirport || !search))">
            {{ $t('flight.noOptions') }}
          </div>
          <div
            v-else
            class="d-none"
          />
        </template>
      </template>

      <template
        slot="selected-option"
        slot-scope="data"
      >
        <span class="text-title text-warning">
          {{ data.city || data.name.split(', ')[0] }}
        </span>
      </template>

      <template #list-header="{ search, searching }">
        <b-card
          v-if="(showDefaultAirport || !search) && !searching"
          no-body
          class="h-25"
        >
          <b-tabs
            vertical
            pills
            nav-wrapper-class="nav-vertical p-0"
            content-class="p-0"
          >
            <b-tab
              v-for="(group, index) in airportOptionsDefault.groups"
              :key="index"
            >
              <template #title>
                <span class="text-uppercase text-left">
                  {{ group.displayName }}
                </span>
              </template>
              <vue-perfect-scrollbar
                style="max-height: 28em"
                :settings="{
                  maxScrollbarLength: 60,
                  wheelPropagation: false,
                }"
                class="ps-customizer-area scroll-area"
              >
                <div
                  v-for="(item, i) in group.airports"
                  :key="i"
                >
                  <b-button
                    variant="flat-warning"
                    class="w-100 text-left text-body"
                    @click="handleChooseAirport(item, true)"
                  >
                    {{ item.name }}
                  </b-button>
                </div>
              </vue-perfect-scrollbar>
            </b-tab>
          </b-tabs>
        </b-card>
      </template>

      <template #option="data">
        <div v-if="!showDefaultAirport">
          <div class="d-flex justify-content-start text-truncate">
            <strong>{{ data.city }}</strong>
            <strong :class="`${data.iata !== valueProp.iata ? 'text-info' : ''} text-truncate font-weight-bold`">{{ data.iata }}</strong>
          </div>
          <div class="d-flex justify-content-between text-truncate">
            <span class="text-truncate">{{ data.name }}, {{ data.country }}</span>
          </div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import {
  BSpinner, BButton, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import {
  ref, computed, toRefs,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { isEmpty, debounce } from 'lodash'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { createPopper } from '@popperjs/core'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BSpinner,
    BButton,
    BTabs,
    BTab,
    BCard,
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    appendToBody: {
      type: Boolean,
      default: true,
    },
    dataProp: {
      type: String,
      default: () => '',
    },
    right: {
      type: Boolean,
      default: false,
    },
    returnValue: {
      type: String,
      default: 'iata',
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = '400px'
      dropdownList.style.width = dropdownWidth
      dropdownList.style.translate = `calc(${dropdownWidth}/2 - ${width})/2`
      // dropdownList.style.translate = `calc((${dropdownWidth} - ${width})/2)`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const { fetchAirports, getAirportGroup: airportOptionsDefault, getAirportByAirportCode } = useBookingHandle()
    const valueProp = toRefs(props).dataProp
    const isLoadingSearchAirport = ref(false)
    const airportOptions = ref([])
    const { toastWarning } = useToast()

    const vSelectRef = ref()

    const getValueProp = computed(() => {
      if (valueProp.value) {
        return getAirportByAirportCode(valueProp.value)
      }
      return false
    })

    function fetchAirportHandle(searchTextAirport = null, callback = () => { }) {
      airportOptions.value = []
      isLoadingSearchAirport.value = true

      fetchAirports(searchTextAirport)
        .then(response => {
          if (response) {
            airportOptions.value = response.data.items
            callback(airportOptions.value[0])
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          isLoadingSearchAirport.value = false
        })
    }

    const handleOpenAirport = () => {
      airportOptions.value = []
    }

    const handleSearchAirport = debounce(searchTextAirport => {
      if (searchTextAirport) fetchAirportHandle(removeAccents(searchTextAirport))
    }, 500)

    const showDefaultAirport = computed(() => isEmpty(airportOptions.value))

    const handleChooseAirport = (item, closeDropdown = false) => {
      if (['country'].includes(props.returnValue) && !item.country) {
        toastWarning({
          title: 'Vui lòng tìm kiếm mã code, tên sân bay !',
        })
      }

      const dataUpdate = ['country'].includes(props.returnValue) ? item.country : item.iata
      emit('update:data-prop', dataUpdate)
      if (closeDropdown) vSelectRef.value.searchEl.blur() // -> close dropdown programmatically
    }

    return {
      airportOptions,
      airportOptionsDefault,
      handleOpenAirport,
      handleSearchAirport,
      showDefaultAirport,
      isLoadingSearchAirport,
      handleChooseAirport,
      valueProp,
      vSelectRef,

      getValueProp,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

#search-airport ::v-deep {
  .vs__dropdown-toggle {
    border: none;
    height: 50px;
    min-width: 120px;
    padding-left: 0;
  }
  .vs__selected-options {
    min-width: 0;
    flex-wrap: nowrap;
    justify-content: center;

    .vs__selected {
      min-width: 0;
    }
    .vs__search {
      position: absolute;
      min-width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
