var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-popup-quick-input-multiple-pax",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "header-bg-variant": "light-info",
      "footer-class": "justify-content-between",
      "title-class": _vm.isMobileView ? 'h4 header-class text-dark' : 'h2 header-class text-dark',
      "title": _vm.$t('flight.quickInputPaxInfo'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel'),
      "ok-disabled": !_vm.validInput
    },
    on: {
      "show": _vm.showHandle,
      "ok": _vm.submitHandle
    }
  }, [_vm.isCreateGroupBooking ? _c('AlertGroupBooking') : _vm._e(), _c('p', {
    staticClass: "mb-50",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('flight.quickInputPaxInfoNote'))
    }
  }), _c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.quickInputPaxInfoNoteQuantity')) + " ")]), _c('p', {
    staticClass: "font-weight-bolder ml-md-1",
    class: _vm.isMobileView ? 'font-small-4' : 'font-medium-2'
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.maxAdults) + " " + _vm._s(_vm.$tc('flight.adultPlural', _vm.maxAdults)) + " "), _vm.maxChildren ? [_vm._v(" - " + _vm._s(_vm.maxChildren) + " " + _vm._s(_vm.$tc('flight.childPlural', _vm.maxChildren)) + " ")] : _vm._e(), _vm.maxInfants ? [_vm._v(" - " + _vm._s(_vm.maxInfants) + " " + _vm._s(_vm.$tc('flight.infantdPlural', _vm.maxInfants)) + " ")] : _vm._e()], 2)]), _c('b-form-textarea', {
    staticClass: "text-uppercase",
    attrs: {
      "rows": "6",
      "debounce": "300"
    },
    model: {
      value: _vm.paxs,
      callback: function callback($$v) {
        _vm.paxs = $$v;
      },
      expression: "paxs"
    }
  }), _vm.errorAlert.length ? _c('div', {
    staticClass: "font-weight-bolder"
  }, [_c('BAlert', {
    staticClass: "px-2 py-1 my-50 fw-700",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("flight['The number of passengers exceeds the number of seats selected, please check again']")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.$t("flight['You have entered the following names in excess']")) + ":")]), _c('div', {
    staticClass: "px-1"
  }, [_vm._l(_vm.errorAlert, function (paxError, errorIndex) {
    return _c('div', {
      key: errorIndex,
      staticClass: "text-uppercase"
    }, [errorIndex < 6 ? _c('div', [_vm._v(" " + _vm._s(errorIndex + 1) + ". " + _vm._s(paxError) + " ")]) : _vm._e()]);
  }), _vm.errorAlert.length >= 6 ? _c('div', [_vm._v(" ... ")]) : _vm._e()], 2)], 1) : _vm._e(), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "d-flex-center gap-2 my-50"
        }, [_c('div', {
          staticClass: "flex-1"
        }, [_c('b-form-group', {
          staticClass: "mb-0"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('flight.bookingCode'),
            "rules": "required|min:6|max:6"
          }
        }, [_c('b-form-input', {
          attrs: {
            "name": _vm.$t('flight.bookingCode'),
            "maxlength": "6",
            "formatter": _vm.removeAccentsUpperCaseFormatter,
            "placeholder": _vm.$t('flight.quickInputPax.bookingCodePlaceholder')
          },
          on: {
            "input": function input(val) {
              return val.toUpperCase();
            }
          },
          model: {
            value: _vm.bookingCode,
            callback: function callback($$v) {
              _vm.bookingCode = $$v;
            },
            expression: "bookingCode"
          }
        })], 1)], 1)], 1), _c('BButton', {
          attrs: {
            "variant": "outline-primary",
            "disabled": invalid
          },
          on: {
            "click": _vm.onQuickInputFromBookingCodeHandle
          }
        }, [_vm._v(" Nhập nhanh từ PNR ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }